<template>
  <vs-card>
    <vs-form autocomplete="off">
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5" align="right">
            <vs-button @click="editData(supplierData._id)" icon-pack="feather" icon="icon-edit">Edit Details</vs-button>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required|max:150'" name="name" data-vv-as="Name" label="Supplier Name" class="w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.name')" v-model="supplierData.name"
              readonly="readonly" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required|email'" name="email" data-vv-as="Email" label="Email Address" class="w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.email')" v-model="supplierData.email"
              readonly="readonly" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required'" name="contactNumber" data-vv-as="Contact Number" label="Contact Number"
              class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.contactNumber')"
              v-model="supplierData.contactNumber" readonly="readonly" />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Delivery and Tracking</h1>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input v-validate="'required|decimal:3'" name="deliveryFee" data-vv-as="Delivery Fee"
              label="Delivery Fee" class="w-full" data-vv-scope="basic" :danger-text="errors.first('basic.deliveryFee')"
              v-model="supplierData.deliveryDetails.deliveryFee" readonly="readonly" />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5">
            <h1>Settings</h1>
            <h6 class="mt-5">Public or private supplier</h6>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full ml-5">
            <vs-radio v-model="privateStatus" vs-value="false" disabled="true"><strong>Public</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12">
            <p>
              Public suppliers allows all accounts to view products, but cannot purchase unless set in the account
              settings.
            </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full mt-5 ml-5">
            <vs-radio v-model="privateStatus" vs-value="true" disabled="true"><strong>Private</strong></vs-radio>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-1/2 ml-12">
            <p>
              Private suppliers and all their products are hidden from the store. Only accounts associated to this
              supplier can view and purchase their products.
            </p>
          </div>
        </vs-col>
        <!--        <vs-col vs-type="flex" vs-align="left" vs-w="12" v-if="privateStatus == 'true'">
                  <div class="w-1/2 mt-5 ml-12" readonly="true">
                    <label class="font-bold">Selected Clinic</label>
                    <v-select
                      multiple
                      :closeOnSelect="false"
                      label="clinicName"
                      :options="clinicList"
                      value="value"
                      :reduce="option => option._id"
                      v-model="supplierData.privateAssignedClinics"
                      data-vv-as="AssignedClinics"
                      data-vv-scope="basic"
                      name="privateAssignedClinics"
                      class="w-full vs-con-input"
                    >
                    </v-select>
                  </div>
                </vs-col>-->
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import SupplierTrackingList from "./SupplierTrackingList";
import vSelect from "vue-select";

export default {
  name: "SupplierDetails",
  components: {
    SupplierTrackingList,
    vSelect
  },
  props: {
    supplierId: {
      type: String
    }
  },
  data() {
    return {
      id: this.supplierId,
      supplierData: {},
      privateStatus: '',
      clinicList: []
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  mounted() { },
  methods: {
    ...mapActions("supplier", ["getSupplierDetails"]),
    ...mapActions("clinic", ["fetchAllClinic"]),

    async fetchSupplierDetails(id) {
      this.$vs.loading();
      let self = this;
      await this.getSupplierDetails(id).then(res => {
        self.supplierData = res.data.data;
        self.supplierData.deliveryDetails.deliveryFee = (
          self.supplierData.deliveryDetails.deliveryFee / 100
        ).toFixed(2);
        this.privateStatus = this.supplierData.privateStatus == true ? "true" : "false";
        this.$vs.loading.close();
      });
    },
    getAllClinic() {
      this.fetchAllClinic()
        .then(async res => {
          this.$vs.loading.close();
          this.clinicList = res.data.data;
        })
    },
    async editData(id) {
      this.$emit("editSupplier", id);
    }
  },
  async created() {
    this.id = this.supplierId;
    await this.getAllClinic();
    await this.fetchSupplierDetails(this.id);
  }
};
</script>

<style scoped>
</style>
