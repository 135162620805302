var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('span',{staticClass:"text-danger mt-1 mb-1",staticStyle:{"margin-left":"3.6rem"}},[_vm._v(_vm._s(_vm.trackingError))]),_vm._l((_vm.localData),function(item,index){return _c('div',{key:index,staticClass:"wrapper"},[_c('div',{staticClass:"tracking ml-5"},[_c('div',{staticClass:"tracking-list-number"},[_c('span',[_vm._v(_vm._s(index + 1))])])]),_c('div',{staticStyle:{"width":"500px"}},[_c('div',{staticClass:"ml-3 mb-3"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",staticStyle:{"width":"100% !important"},attrs:{"success":!_vm.errors.first('courierName') && _vm.localData[index].courierName != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('courierName') && _vm.localData[index].courierName == ''
              ? true
              : false,"danger-text":_vm.errors.first('courierName'),"name":"courierName","data-vv-as":"Courier Name","placeholder":"Courier Name","readonly":_vm.task === 'View'},on:{"keyup":function($event){return _vm.$emit('change', _vm.localData)}},model:{value:(item.courierName),callback:function ($$v) {_vm.$set(item, "courierName", $$v)},expression:"item.courierName"}})],1),_c('div',{staticClass:"ml-3 mb-5"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"success":!_vm.errors.first('trackingLink') &&
              _vm.localData[index].trackingLink != '',"val-icon-success":"done","val-icon-danger":"error","danger":_vm.errors.first('trackingLink') &&
            _vm.localData[index].trackingLink == ''
              ? true
              : false,"danger-text":_vm.errors.first('trackingLink'),"name":"trackingLink","data-vv-as":"Tracking Link","placeholder":"Tracking Link","readonly":_vm.task === 'View'},on:{"keyup":function($event){return _vm.$emit('change', _vm.localData)}},model:{value:(item.trackingLink),callback:function ($$v) {_vm.$set(item, "trackingLink", $$v)},expression:"item.trackingLink"}})],1)]),(_vm.task !== 'View')?_c('div',{staticStyle:{"color":"maroon","cursor":"pointer","margin-left":"5px"},on:{"click":function($event){return _vm.removeTrackingItem(_vm.localData[index], index)}}},[_c('p',[_c('feather-icon',{attrs:{"icon":"XIcon","size":"1"}})],1)]):_vm._e()])}),(_vm.task !== 'View')?_c('div',{staticClass:"addMore",on:{"click":_vm.addMoreTrackingOption}},[_c('feather-icon',{staticClass:"w-4 h-4",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Add another tracking option")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }