<template>
  <vx-card>
    <div style="display:flex;flex-direction:row;justify-content: space-evenly;">
      <div style="">
        <vs-tabs class="supplier-detail">
          <vs-tab label="Supplier Details">
            <div>
              <SupplierDetails @editSupplier="editSupplier" :supplierId="supplierId" />
            </div>
          </vs-tab>
          <vs-tab label="Products">
            <div>
              <ProductList :supplierId="supplierId" />
            </div>
          </vs-tab>
          <vs-tab label="Orders">
            <div>
              <OrderList :supplierId="supplierId" @viewOrderDetails="viewOrderDetails" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <div>test</div>
    </div>
  </vx-card>
</template>

<script>
import SupplierDetails from "../../../components/WebStore/suppliers/SupplierDetails";
import ProductList from "../../../components/WebStore/suppliers/ProductList";
import OrderList from "../../../components/WebStore/suppliers/OrderList";

export default {
  name: 'View-Supplier',
  components: {
    SupplierDetails,
    ProductList,
    OrderList
  },
  data: () => ({
    supplierId: ''
  }),
  methods: {
    editSupplier(id) {
      this.$router.push({
        name: "adminStoreEditSuppliers",
        params: { supplierId: id }
      });
    },
    viewOrderDetails(data) {
      this.$router.push({
        name: "adminStoreViewSuppliersOrderDetail",
        params: { orderId: data.orderId, supplierId: data.supplierId }
      });
    },
  },
  async created() {
    this.supplierId = this.$route.params.supplierId;
  }
};
</script>
