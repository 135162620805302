<template>
  <section>
    <span class="text-danger mt-1 mb-1" style="margin-left: 3.6rem;">{{
      trackingError
    }}</span>
    <div class="wrapper" v-for="(item, index) in localData" :key="index">
      <div class="tracking ml-5">
        <div class="tracking-list-number">
          <span>{{ index + 1 }}</span>
        </div>
      </div>

      <div style="width: 500px">
        <div class="ml-3 mb-3">
          <vs-input
            :success="
              !errors.first('courierName') && localData[index].courierName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('courierName') && localData[index].courierName == ''
                ? true
                : false
            "
            v-validate="'required'"
            :danger-text="errors.first('courierName')"
            name="courierName"
            data-vv-as="Courier Name"
            placeholder="Courier Name"
            class="w-full"
            style="width: 100% !important"
            v-model="item.courierName"
            @keyup="$emit('change', localData)"
            :readonly="task === 'View'"
          />
        </div>

        <div class="ml-3 mb-5">
          <vs-input
            :success="
              !errors.first('trackingLink') &&
                localData[index].trackingLink != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('trackingLink') &&
              localData[index].trackingLink == ''
                ? true
                : false
            "
            v-validate="'required'"
            :danger-text="errors.first('trackingLink')"
            name="trackingLink"
            data-vv-as="Tracking Link"
            placeholder="Tracking Link"
            class="w-full"
            v-model="item.trackingLink"
            @keyup="$emit('change', localData)"
            :readonly="task === 'View'"
          />
        </div>
      </div>

      <div
        v-if="task !== 'View'"
        style="color: maroon; cursor: pointer; margin-left: 5px;"
        @click="removeTrackingItem(localData[index], index)"
      >
        <p>
          <feather-icon icon="XIcon" size="1" />
        </p>
      </div>
    </div>
    <div v-if="task !== 'View'" class="addMore" @click="addMoreTrackingOption">
      <feather-icon icon="PlusIcon" class="w-4 h-4" />
      <span class="ml-1">Add another tracking option</span>
    </div>
  </section>
</template>

<script>
export default {
  name: "SupplierTrackingList",
  props: {
    list: {
      type: Array,
      required: true
    },
    trackingError: {
      type: String,
      required: false,
      default: ""
    },
    task: {
      type: String,
      required: false,
      default: "Edit"
    }
  },
  inject: ["$validator"],
  data() {
    return {
      localData: this.list
    };
  },
  mounted() {
    if (this.list.length > 0) {
      this.localData = JSON.parse(JSON.stringify(this.list));
    } else {
      this.localData = [
        {
          courierName: "",
          trackingLink: ""
        }
      ];
    }
    this.$emit("change", this.localData);
  },
  methods: {
    addMoreTrackingOption() {
      this.localData.push({
        courierName: "",
        trackingLink: ""
      });

      this.$emit("change", this.localData);
    },
    removeTrackingItem(localData, index) {
      this.localData.splice(index, 1);
      if (localData && localData._id && this.task === "Edit")
        this.$emit("remove", localData._id);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: baseline;
}

.tracking {
  display: flex;
  align-items: center;
  justify-content: center;

  .tracking-list-number {
    width: 25px;
    height: 25px;
    background: #3dc9b3;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
}

.addMore {
  margin-left: 3.5rem;
  display: flex;
  font-weight: 500;
  cursor: pointer;
  color: #3dc9b3;
}
</style>
